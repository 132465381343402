import Router from 'next/router'
import startCase from 'lodash/startCase'
import { uniq } from 'lodash'
import dayjs from 'dayjs'

// commonly used utility functions
export {
  toDollars, // 10000 => $10,000
  digitsOnly, // parse string and return only integers
  redirect,
  errorMessage,
  render404,
  scrollToRef,
  getCardPositions,
  currentPlansOnly,
  validAspectRatio,
  getAddressObject,
  generateRandomString,
  onMobileDevice,
  ellipsized,
  getParameterByName,
  publicFacingStatus,
}

// make sure that it's within +- 3% of the 3:2 ratio
const validAspectRatio = (width, height) => {
  const upperBounds = width * 0.667 * 1.03
  const lowerBounds = width * 0.667 * 0.97

  return upperBounds >= height && height >= lowerBounds
}

const generateRandomString = length => {
  var s = ''
  do {
    s += Math.random().toString(36).substr(2)
  } while (s.length < length)
  s = s.substr(0, length)

  return s
}

const currentPlansOnly = (plans = {}) => {
  const planKeys = Object.keys(plans).filter(k => k.includes('tier_'))

  const currentPlans = planKeys.reduce((acc, currentVal) => {
    acc[currentVal] = plans[currentVal]
    return acc
  }, {})

  return currentPlans
}

const getCardPositions = card => {
  if (!card.side) {
    return null
  }

  return {
    top: card.side === 'front' ? `${card.percentCoordinates.top_coordinate}%` : 50,
    left: card.side === 'front' ? `${card.percentCoordinates.left_coordinate}%` : 40,
    width: card.side === 'front' ? `${card.percentCoordinates.width}%` : 'calc(100% - 80px)',
    height: card.side === 'front' ? `${card.percentCoordinates.height}%` : 'calc(100% - 100px)',
  }
}

const scrollToRef = (ref, offset = 0) => {
  window.scrollTo({ top: ref.current.offsetTop + offset, behavior: 'smooth' })
}

const errorMessage = e => {
  const serverResponse = e?.response?.data?.errors || e?.response?.data?.message

  if (serverResponse) {
    if (typeof serverResponse === 'string') {
      return serverResponse
    } else {
      return Object.values(serverResponse)[0]
    }
  }

  return e.message
}

const toDollars = (number, minimumFractionDigits = 2) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
  }).format(number)
}

const digitsOnly = val => {
  return val ? val.replace(/\D/g, '') : ''
}

const redirect = (to = '/login', res) => {
  if (typeof window !== 'undefined') {
    Router.push(to)
  } else {
    res.writeHead(302, {
      Location: to,
    })
    res.end()
  }
}

function render404(to) {
  if (process.browser) {
    // will cause a server side error page to be shown by refreshing
    // sort of hacky but better than alternatives
    window.location = window.location.origin + to
  }

  const err = new Error()
  err.code = 'ENOENT'
  throw err
}

//https://medium.com/@almestaadmicadiab/how-to-parse-google-maps-address-components-geocoder-response-774d1f3375d
const getAddressObject = (addressComponents = []) => {
  const shouldBeComponent = {
    streetNumber: ['street_number'],
    postalCode: ['postal_code'],
    street: ['street_address', 'route'],
    state: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
    country: ['country'],
  }

  var address = {
    streetNumber: '',
    postalCode: '',
    street: '',
    state: '',
    city: '',
    country: '',
  }

  addressComponents.forEach(component => {
    for (var shouldBe in shouldBeComponent) {
      if (shouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === 'country') {
          address[shouldBe] = component.short_name
        } else {
          address[shouldBe] = component.long_name
        }
      }
    }
  })

  return address
}

const onMobileDevice = () => {
  // https://stackoverflow.com/a/11381730/1424568 - works for mobile and tablet
  if (!process.browser) {
    return false
  }

  let check = false

  ;(agent => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        agent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        agent.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

function ellipsized(text, maxLength) {
  if (text && text.length >= maxLength) {
    return text.substr(0, maxLength) + '...'
  }
  return text
}

function getParameterByName(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(location.search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

const publicFacingStatus = status => {
  if (status === 'complete') {
    return 'mailed'
  } else if (status === 'written') {
    return 'written'
  } else if (status === 'cancelled') {
    return 'cancelled'
  } else {
    return 'processing'
  }
}

export const isCbsAdminOrRegularUser = user => {
  if (user.teamId !== '5ec6fe5ea56ed60017c1fb7b' || user.role === 'owner') {
    return true
  } else {
    return false
  }
}

export const isCbsTeamMember = user => {
  return user.teamId === '5ec6fe5ea56ed60017c1fb7b'
}

// returns an array of merge variables used in the text provided minus the curly braces
export const mergeVariableListBuilder = msg => {
  let returnArray = []
  // convert msg to array
  msg.split(' ').forEach(item => {
    if (item.indexOf('{') >= 0 && item.indexOf('}' >= 0)) {
      returnArray.push(item.substring(item.indexOf('{') + 1, item.indexOf('}')))
    }
  })
  return returnArray
}

// takes an array of merge vars used (no curly braces) and the list of recipients
// and returns a unique list of variables that don't exist in at least one recipient
export const checkMergeVars = (mergeVars, recipients) => {
  let invalidMergeVars = []
  if (mergeVars && mergeVars.length > 0 && recipients && recipients.length > 0) {
    recipients.forEach(r => {
      mergeVars.forEach(m => {
        if (!r[m]) {
          invalidMergeVars.push(m)
        }
      })
    })
  }
  return uniq(invalidMergeVars)
}

export const getDaysArray = (start, end) => {
  for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt))
  }
  return arr
}

export const datesAreOnSameDay = (x, y) =>
  x.getFullYear() === y.getFullYear() && x.getMonth() === y.getMonth() && x.getDate() === y.getDate()

export const getDiffDays = (start, end) => {
  if (!start || !end) return 0

  const diffTime = Math.abs(end - start)

  return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}

export const getGraphData = (items, startDate, endDate, objProperty, orderKey = 'orders') => {
  const arrayOfDays = getDaysArray(startDate, endDate)

  return arrayOfDays.map(day => {
    const format = arrayOfDays.length > 90 ? 'MMM DD, YYYY' : 'MMM DD'

    const obj = {
      day: dayjs(day).format(format),
      [orderKey]: 0,
    }

    items.forEach(order => {
      const orderDate = new Date(order[objProperty])
      const currentDay = new Date(day)

      if (datesAreOnSameDay(orderDate, currentDay)) {
        obj[orderKey]++
      }
    })

    return obj
  })
}
